import React, {
  useContext,
  useCallback,
  useMemo,
  useEffect,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { navigate } from 'gatsby';
import MainHeader from '../../components/main-header/main-header';
import PageHeader from '../../components/page-header/page-header';
import Button from '../../components/button/button';
import {
  cancelEdit,
  changeValorParcela,
  isEditValid,
} from '../../helpers/editQuotation';
import MainFooter from '../../components/main-footer/main-footer';
import Dropdown from '../../components/dropdown/dropdown';
import RadioGroup from '../../components/radiogroup/radiogroup';
import { DataContext } from '../../data/DataProvider';
import classesConstrucao from '../../data/classesConstrucao';
import cn from 'classnames';
import './index.scss';
import DefaultLayout from '../../components/layouts/default-layout';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

const block = 'page-avaliacao-de-risco';
const isopainel = [
  {
    label: 'Abaixo de 25%',
    value: false,
  },
  {
    label: 'Acima de 25%',
    value: true,
  },
];
const yesOrNot = [
  {
    label: 'Sim',
    value: true,
  },
  {
    label: 'Não',
    value: false,
  },
];

const local = [
  {
    label: 'Box',
    value: 1,
  },
  {
    label: 'Galeria comercial',
    value: 2,
  },
  {
    label: 'Condomínio comercial',
    value: 3,
  },
  {
    label: 'Shopping',
    value: 4,
  },
  {
    label: 'Rua',
    value: 5,
  },
];
const Page = ({ location }) => {
  const {
    quotation,
    setQuotation,
    originalQuotation,
    setModal,
    setOriginalQuotation,
    responseMotor,
  } = useContext(DataContext);
  const [disableSave, setDisableSave] = useState(false);
  const { handleSubmit } = useForm();

  const cancel = () => {
    cancelEdit(setQuotation, originalQuotation);
  };
  const onSubmit = () => {
    const newData = {
      ...quotation,
      avaliacaoRisco: {
        ...quotation.avaliacaoRisco,
        classeConstrucao: quotation.avaliacaoRisco.classeConstrucao,
        isopainelConstrucao: quotation.avaliacaoRisco.isopainelConstrucao,
        imovelDesocupado: quotation.avaliacaoRisco.imovelDesocupado,
        localizacaoRisco: quotation.avaliacaoRisco.localizacaoRisco,
        imovelTombado: quotation.avaliacaoRisco.imovelTombado,
      },
      isProposal: false,
    };
    setQuotation({ ...newData });
    if (quotation?.propostaId) {
      setDisableSave(true);
      return changeValorParcela(
        quotation,
        responseMotor.response,
        setModal,
        setDisableSave,
        setOriginalQuotation
      );
    }
    return navigate('/sistemas-protecionais');
  };
  useEffect(() => {
    if (!quotation || !Object.keys(quotation).length) navigate('/');
    if (quotation && !quotation?.informacoesSeguro)
      return navigate('/informacoes-do-seguro');
  }, [quotation]);

  const classeConstrucaoChangeHandler = useCallback(
    (selectedItem) => {
      trackCustomEvent({
        category: 'Classe de construção',
        action: 'Click',
        label: selectedItem.label,
      });
      setQuotation({
        ...quotation,
        avaliacaoRisco: {
          ...quotation.avaliacaoRisco,
          classeConstrucao: selectedItem.value,
        },
      });
    },
    [quotation, setQuotation]
  );

  const isopanelConstrucaoChangeHandler = useCallback(
    (selectedItem) => {
      trackCustomEvent({
        category: 'Isopanel',
        action: 'Click',
        label: selectedItem.value,
      });
      setQuotation({
        ...quotation,
        avaliacaoRisco: {
          ...quotation.avaliacaoRisco,
          isopainelConstrucao: selectedItem.value,
        },
      });
    },
    [quotation, setQuotation]
  );

  const imovelDesocupadoChangeHandler = useCallback(
    (selectedItem) => {
      trackCustomEvent({
        category: 'Imóvel desocupado',
        action: 'Click',
        label: selectedItem.value,
      });
      setQuotation({
        ...quotation,
        avaliacaoRisco: {
          ...quotation.avaliacaoRisco,
          imovelDesocupado: selectedItem.value,
        },
      });
    },
    [quotation, setQuotation]
  );

  const localizacaoRiscoChangeHandler = useCallback(
    (selectedItem) => {
      trackCustomEvent({
        category: 'Localização do risco',
        action: 'Click',
        label: selectedItem.value,
      });
      setQuotation({
        ...quotation,
        avaliacaoRisco: {
          ...quotation.avaliacaoRisco,
          localizacaoRisco: selectedItem.value,
        },
      });
    },
    [quotation, setQuotation]
  );

  const imovelTombadoChangeHandler = useCallback(
    (selectedItem) => {
      trackCustomEvent({
        category: 'Imóvel tombado',
        action: 'Click',
        label: selectedItem.value,
      });
      setQuotation({
        ...quotation,
        avaliacaoRisco: {
          ...quotation.avaliacaoRisco,
          imovelTombado: selectedItem.value,
        },
      });
    },
    [quotation, setQuotation]
  );

  const isValid = useMemo(() => {
    if (
      quotation?.avaliacaoRisco?.classeConstrucao === 3 ||
      quotation?.avaliacaoRisco?.classeConstrucao === 4 ||
      quotation?.avaliacaoRisco?.isopainelConstrucao ||
      quotation?.avaliacaoRisco?.imovelDesocupado ||
      quotation?.avaliacaoRisco.localizacaoRisco < 3 ||
      quotation?.avaliacaoRisco.imovelTombado
    ) {
      return false;
    }

    return true;
  }, [quotation?.avaliacaoRisco]);

  return (
    <DefaultLayout currentPagePath={location.pathname}>
      <main className={block}>
        <MainHeader />
        <PageHeader
          backButtonHandler={() => navigate('/informacoes-do-seguro')}
          editMode={!!quotation?.propostaId}
          title="Questionário de avaliação de risco"
          description={
            <span>
              A informações da avaliação de risco influenciam no cálculo do
              prêmio e na aceitação da proposta.
              <br />O valor será atualizado no box de preço (ao lado) e você
              será informado caso não aceitarmos os riscos.
            </span>
          }
          breadCrumbs={[
            {
              label: 'Início',
              path: '/',
            },
            {
              label: 'CNPJ',
              path: '/cnpj',
            },
            {
              label: 'Dados da empresa',
              path: '/dados-da-empresa',
            },
            {
              label: 'Informações do seguro',
              path: '/informacoes-do-seguro',
            },
            {
              label: 'Avaliação de risco',
              path: '/avaliacao-de-risco',
            },
          ]}
          fixed
          hide={quotation?.personalizado}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={`container--large ${block}--fixed`}>
            <div className={`${block}--row`}>
              <div className={`${block}--item`}>
                <fieldset className="fieldset">
                  <span className={`${block}__label`}>
                    É patrimônio histórico ou tombado?
                  </span>
                  <Dropdown
                    placeholder="Selecione"
                    items={yesOrNot}
                    changeHandler={imovelTombadoChangeHandler}
                    selectedValue={quotation?.avaliacaoRisco?.imovelTombado}
                  />
                </fieldset>
              </div>
              <div className={`${block}--item`}>
                <fieldset className="fieldset">
                  <span className={`${block}__label`}>
                    % de isopainel na construção
                  </span>
                  <Dropdown
                    placeholder="Selecione"
                    items={isopainel}
                    changeHandler={isopanelConstrucaoChangeHandler}
                    selectedValue={
                      quotation?.avaliacaoRisco?.isopainelConstrucao
                    }
                  />
                </fieldset>
              </div>
            </div>
            <div className={`${block}--row`}>
              <div className={`${block}--item`}>
                <fieldset className="fieldset">
                  <span className={`${block}__label`}>Imóvel desocupado?</span>
                  <Dropdown
                    placeholder="Selecione"
                    items={yesOrNot}
                    changeHandler={imovelDesocupadoChangeHandler}
                    selectedValue={quotation?.avaliacaoRisco?.imovelDesocupado}
                  />
                </fieldset>
              </div>
              <div className={`${block}--item`}>
                <fieldset className="fieldset">
                  <span className={`${block}__label`}>
                    Localização do risco
                  </span>
                  <Dropdown
                    placeholder="Selecione"
                    items={local}
                    changeHandler={localizacaoRiscoChangeHandler}
                    selectedValue={quotation?.avaliacaoRisco?.localizacaoRisco}
                  />
                </fieldset>
              </div>
            </div>
            <div className={`${block}--row`}>
              <div className={cn(`${block}--item2`, `${block}--full`)}>
                <fieldset className="fieldset">
                  <span className={`${block}__label`}>
                    Selecione o tipo de construção:
                  </span>
                  <RadioGroup
                    className={`${block}__construction-type-group`}
                    name="construcao"
                    options={classesConstrucao}
                    changeHandler={classeConstrucaoChangeHandler}
                    selectedValue={quotation?.avaliacaoRisco.classeConstrucao}
                  />
                </fieldset>
              </div>
            </div>
          </div>

          <div className={`container--large`}>
            {isEditValid(quotation) && (
              <div className="action_buttons">
                <Button
                  className="button_cta"
                  type="button"
                  theme={'secondary'}
                  onClick={cancel}
                  disabled={disableSave}
                >
                  Cancelar edição
                </Button>
                <Button
                  className={`button_cta button--margin-left`}
                  type="submit"
                  theme={'tertiary'}
                  disabled={!isValid || disableSave}
                >
                  Salvar edição
                </Button>
              </div>
            )}
            {!quotation?.propostaId && (
              <Button type="submit" className="button_cta" disabled={!isValid}>
                Avançar
              </Button>
            )}
          </div>
        </form>

        <MainFooter />
      </main>
    </DefaultLayout>
  );
};

export default Page;
