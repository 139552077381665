import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './radiogroup.scss';

const block = 'radio-button';

const RadioGroup = ({
  className,
  name,
  options,
  changeHandler,
  selectedValue,
}) => {
  return (
    <div className={`${block} ${className}`}>
      {options.map(({ label, description, value }, index) => {
        return (
          <div
            className={cn(`${block}__options`, {
              [`${block}__options--selected`]: selectedValue === value,
            })}
            key={index}
          >
            <input
              id={index}
              name={name}
              type="radio"
              value={value}
              checked={selectedValue === value}
              onChange={() => changeHandler({ value, label, description })}
            />
            <label className={`${block}__options--label`} htmlFor={index}>
              <p>{label}</p>
              <p className={`${block}__options--description`}>{description}</p>
            </label>
          </div>
        );
      })}
    </div>
  );
};

RadioGroup.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      description: PropTypes.string,
      value: PropTypes.number.isRequired,
    })
  ),
  changeHandler: PropTypes.func.isRequired,
  selectedValue: PropTypes.any,
};
export default RadioGroup;
