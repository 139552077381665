const classesConstrucao = [
  {
    label: 'Sólida',
    description:
      'Edificação integral em alvenaria, com cobertura por telhados incombustíveis sem laje de concreto permitindo-se travejamento de madeira ou metal.',
    value: 1,
  },
  {
    label: 'Superior',
    description:
      'Edificação em alvenaria, laje de concreto, com cobertura ou não por telhados incombustíveis permitindo-se travejamento de madeira ou metal.',
    value: 2,
  },
  {
    label: 'Mista',
    description:
      'Paredes externas com até 25% de material combustível, sendo sua cobertura com material incombustível, permitindo-se travejamento de madeira ou metal.',
    value: 3,
  },
  {
    label: 'Inferior',
    description:
      'Paredes externas com mais 25% de material combustível e/ou outros materiais que não seja alvenaria.',
    value: 4,
  },
];

export default classesConstrucao;
